.component {
  width: 110px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s ease-in-out;

  &.top {
    bottom: 120%;
  }

  &.bottom {
    top: 120%;
  }
}
