.component {
  height: auto;
  display: flex;
  justify-content: flex-end;
  margin: 0 20px;
  position: sticky;
  top: 20px;

  .newConversation {
    position: relative;

    .newConversationImg {
      opacity: 0.3;
      transition: 0.2s;
    }

    .tooltip {
      visibility: hidden;
      width: 110px;
      background-color: black;
      color: #fff;
      border-radius: 6px;
      padding: 30px;
      position: absolute;
      bottom: 120%;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
    }

    &:hover {
      .newConversationImg {
        cursor: pointer;
        opacity: 1;
      }

      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
