@font-face {
  font-family: "Open Sans-Regular";
  src: url(/fonts/OpenSans-Regular.ttf);
}

body {
  font-family: "Open Sans-Regular";
}

.container {
  display: flex;
  flex-direction: column;
  gap: 70px;
  padding: 60px 0;

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 16px;
  }
}
