@use "variables" as *;

.container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .topper {
    color: #555555;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 60px);
    margin: auto;
  }

  .info {
    opacity: 0.3;
    transition: 0.2s;
    position: relative;

    &:hover {
      cursor: pointer;
      opacity: 1;

      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }

    .tooltip {
      visibility: hidden;
      width: 320px;
      background-color: black;
      color: #fff;
      border-radius: 6px;
      padding: 30px;
      position: absolute;
      bottom: 120%;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
    }
  }

  .history {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-inline-end: 26px;
    padding-inline-start: 30px;

    overflow-y: auto;
    max-height: 30vh;

    // Move scrollbar in from the right edge
    border-right: 4px solid transparent;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.3);
      border-radius: 4px;

      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }

    // Hide scrollbar by default
    &:not(:hover) {
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    // Show scrollbar and adjust padding on hover when scrollable
    &:hover.scrollable {
      padding-inline-end: 22px;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 5px;

      .imgAndLabel {
        display: flex;
        align-items: center;
        gap: 10px;
        overflow: hidden;

        img {
          max-width: 16px;
        }

        li {
          opacity: 0.7;
          transition: 0.2s;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }
      }

      .dateAndTrash {
        display: flex;
        align-self: center;
        gap: 5px;

        span {
          opacity: 0.7;
        }

        img {
          opacity: 0.4;
          transition: 0.2s;

          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }
      }
    }

    .active {
      font-weight: bold;
      opacity: 1;
    }
  }

  .disableHistory {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;

    span {
      color: #555555;

      &:hover {
        cursor: pointer;
        color: white;
      }
    }
  }

  .spinner {
    img {
      display: block;
      width: 50px;
      margin: 0 auto 30px;
    }
  }
}
