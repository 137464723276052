@use "variables" as *;

.container {
  margin-top: 20px;

  .title {
    padding-left: 30px;
    color: #555555;
    display: block;
  }

  .buttonActivate {
    display: block;
    width: 90%;
    height: 50px;
    margin: 20px auto;
    background: rgb(21, 34, 68);
    background: linear-gradient(
      180deg,
      rgba(21, 34, 68, 1) 0%,
      rgba(31, 34, 89, 1) 47%,
      rgba(56, 34, 109, 1) 100%
    );
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    outline: none;

    &:hover {
      cursor: pointer;
      scale: 1.05;
    }
  }
}
