$base-color: #202020;
$off-base-color: #424242;
$light-blue-color: #8599bb;
$dark-blue-color: #3c4e6c;
$orange-color: #f39200;
$green-color: #59b659;
$darker-green-color: #51a851;
$green-blue-color: #284e57;
$base-shadow: 0px 15px 35px 4px rgba(0, 0, 0, 0.55);
$login-blue: #4389e3;

/* MessageBanner */
$failure-red: #e74b4b;
$success-green: #408640;
