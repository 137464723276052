@use "variables" as *;

.container {
  margin: 0 100px;
  position: relative;
  min-height: 100px;
  background-color: #191a1c;
  border-radius: 20px;

  color: white;
  white-space: pre-line;

  p {
    padding-left: 85px;
    padding-right: 65px;
    margin-block-end: 32px;
  }

  .user {
    display: flex;
    gap: 15px;
    padding-top: 30px;
    padding-left: 30px;

    img {
      border-radius: 50%;
    }

    span {
      color: #747070;
      align-self: center;
    }
  }

  .image {
    display: flex;
    justify-content: center;
    padding: 0 100px 30px;

    img {
      max-width: 100%;
    }
  }

}
