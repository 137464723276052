.component {
  position: absolute;
  top: -20px;
  right: 30px;
  display: flex;
  gap: 10px;
  color: white;

  span {
    &:hover {
      cursor: pointer;
    }
  }
}
