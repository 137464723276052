@use "variables" as *;

.container {
  margin: 0 100px;
  position: relative;
  background-color: #191a1c;
  border-radius: 20px;

  .hej {
    display: flex;
    gap: 15px;
    padding-top: 30px;
    padding-left: 30px;

    span {
      color: #747070;
      align-self: center;
    }
  }

  p {
    padding-left: 85px;
    padding-right: 65px;
    color: white;
  }
}
