@use "variables" as *;

.component {
  color: white;

  p {
    white-space: pre-line;
    font-size: 16px;
    font-style: italic;
    opacity: 0.55;

    &:last-of-type {
      margin-block-end: 32px;
    }
  }

  .info {
    opacity: 0.3;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      opacity: 1;

      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }

    .tooltip {
      visibility: hidden;
      width: 300px;
      background-color: black;
      color: #fff;
      border-radius: 6px;
      padding: 30px;
      position: absolute;
      bottom: 120%;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
    }
  }
}
