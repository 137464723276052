@use "variables" as *;

.component {
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  max-height: 200px;
  border-radius: 10px;
  padding: 15px 60px 15px 15px;
  font-size: 16px;
  overflow-y: hidden;
  resize: none;
  color: white;
  background-color: #191a1c;
  border: none;

  &:focus {
    outline: none !important;
  }
}
