@use "variables" as *;

.uploadedFile {
  position: relative;
  display: flex;
  align-items: end;
  gap: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: "Arial";
  color: white;
  background-color: #191a1c;
  padding: 8px 16px;
  border-radius: 20px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  .clear {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -10px;
    border-radius: 50%;
    background-color: white;
    border: none;
    cursor: pointer;
  }
}
