@use "variables" as *;

.container {
  z-index: 3;
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;

  .close {
    width: 20px;
    align-self: end;
    margin-top: -10px;
    margin-right: -10px;

    &:hover {
      cursor: pointer;
      scale: 1.05;
    }
  }

  .failure {
    color: white;
    background-color: $failure-red;

    &:hover .progress {
      animation-play-state: paused;
    }
  }

  .success {
    color: white;
    background-color: $success-green;

    &:hover .progress {
      animation-play-state: paused;
    }
  }

  .topMiddle {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    width: 550px;
    animation: slideIn 0.3s ease-out;

    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }

  .progressBar {
    position: absolute;
    top: -3px;
    left: 8px;
    right: 8px;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50px 50px 0 0;
    overflow: hidden;
  }

  .progress {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    transform-origin: left;
    animation: progress 8s linear forwards;
    will-change: transform;
  }

  @keyframes progress {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

.fadeOut {
  animation: fadeOut 0.2s ease-out forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(20px);
  }
}
