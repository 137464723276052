@use "variables" as *;

.container {
  margin: 90px auto 0;

  .logo {
    img {
      width: 100%;
      max-width: 534px;
      height: auto;
    }
  }

  button {
    margin: 60px auto;
    width: fit-content;
    font-size: 16px;
    color: whitesmoke;
    background-color: $login-blue;
    border: 1px solid white;
    border-radius: 10px;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 21px;
      background-image: url("../img/microsoft-logo-white.svg");
      background-size: contain;
      background-repeat: no-repeat;
      vertical-align: middle;
    }

    &:hover {
      cursor: pointer;
      background-color: white;
      color: black;

      &::before {
        background-image: url("../img/microsoft-logo-black.svg");
      }
    }
  }
}
