@use "variables" as *;

.container {
  margin: 0 100px;
  position: relative;
  background-color: #191a1c;
  border-radius: 20px;

  .topper {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 0;

    button {
      max-width: 30px;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }
    }

    .hej {
      display: flex;
      gap: 15px;

      span {
        color: #747070;
        align-self: center;
      }
    }
  }

  .padded {
    padding-left: 85px;
    padding-right: 65px;

    .copyablePre {
      display: flex;
      flex-direction: column;

      button {
        width: auto;
        margin-left: auto;
        margin-top: -16px;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        color: #747070;
        transition: 0.1s;

        &:hover {
          color: white;
        }
      }
    }
  }

  .image {
    width: 100%;
  }

  .copyableBlock,
  .copyableResponse {
    display: flex;
    justify-content: flex-end;
    margin: 0 15px 10px;

    .copyClipboardIcon {
      cursor: pointer;
      opacity: 0.3;
      transition: 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }

  .copyableResponse {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
