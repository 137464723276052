@use "variables" as *;

.dialog {
  display: flex;
  flex-direction: column;
  width: clamp(330px, 50%, 390px);
  height: fit-content;
  padding: 40px;
  border-radius: 20px;
  border: none;
  color: white;
  background-color: #191a1c;

  @media screen and (min-height: 1000px) {
    margin-top: 200px;
  }

  &::backdrop {
    background-color: hsla(0, 0%, 0%, 0.5);
    backdrop-filter: blur(2px);
  }

  img {
    margin: auto;
  }

  h1 {
    text-align: center;
  }

  p {
    margin-top: 8px;
    margin-bottom: 15px;
  }

  pre {
    font-size: 14px;
    text-wrap: wrap;
  }

  .buttonContainer {
    display: flex;
    gap: 15px;

    button {
      width: 100%;
      color: white;
      font-size: 20px;
      background-color: $login-blue;
      outline: none;
      border: none;
      border-radius: 10px;
      padding: 15px 30px;
      margin: 25px 0 5px;

      &:hover {
        cursor: pointer;
        scale: 1.05;
      }

      img {
        width: 18px;
      }
    }
  }

  &.initialPolicy {
    .logo {
      width: 200px;
      height: auto;
    }

    .checkboxContainer {
      margin-top: 20px;

      label {
        display: flex;
        justify-content: center;
        gap: 10px;

        input {
          min-width: 20px;
          min-height: 20px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .warning {
        color: $failure-red;
        margin-top: 30px;
        text-align: center;
      }
    }
  }

  // &.historyConsent {}

  // &.deactivateConversationHistory {}
}
